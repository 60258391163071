import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from '../i18n/en.json';
import ar from '../i18n/ar.json';
import fr from '../i18n/fr.json';
import de from '../i18n/de.json';

// import { DateTime } from 'luxon';

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  ar: {
    translation: ar,
  },
  de: {
    translation: de,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    resources,
    lng: 'en',
    react: {
      useSuspense: false,
    },
  });

// i18n.changeLanguage('ar');

// new usage
// i18n.services.formatter.add('DATE_HUGE', (value, lng, options) => {
//   return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime.DATE_HUGE);
// });

export default i18n;
